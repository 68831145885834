import { BlockUserService } from 'src/app/utils/services/block-user.service';
import { Subject, Subscription } from 'rxjs';
import { ChatService } from './../chat/chat.service';
import { ChangeDetectorRef, Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { thumbnailDB } from './indexedDB/user-list.db';
import { isPlatformBrowser } from '@angular/common';
import { breakpoint } from '../utils/constants/constants';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root',
})
export class UserListService {

  _userlist = [];
  set userlist(userlist) {

    if (userlist === null) {
      this._userlist = [];
    } else {

      this._userlist = [...this._userlist, ...userlist];

    }

    console.log("Userlist: ");
    console.log(this._userlist);

  }
  get userlist() {
    return this._userlist;
  }
  userlistIDs = [];
  userListLength = 0;
  $storeVirtualScrollOffset: Subject<boolean> = new Subject<boolean>();

  thumbnails = thumbnailDB;
  inMobileView = false;

  constructor(
    private chatService: ChatService,
    private blockUserService: BlockUserService,
    @Inject(PLATFORM_ID) private platformId: string,
    private breakpointObserver: BreakpointObserver
  ) {

    this.startBlockingStatusChangeListener();
    this.cleanUserListWhenMutingUsers();

    if (isPlatformBrowser(this.platformId)) {

      if (window.innerWidth <= breakpoint.mobile) this.inMobileView = true;
      this.breakpointObserver
        .observe([`(max-width: ${breakpoint.mobile}px)`])
        .subscribe((state) => { if (state.matches) this.inMobileView = true });
    }


  }
  startBlockingStatusChangeListener() {

    this.blockUserService.$notifyAboutBlockingStatusChange.subscribe((blockedId: string) => {

      for (let row of this.userlist) {

        for (let user of row) {

          if (user._id.toString() == blockedId) {

            user.blocked = !user.blocked;
            break;

          }
        }

      }

    });

  }
  cleanUserListWhenMutingUsers() {

    this.chatService.$mutedUser.subscribe(mutedId => {

      let index = 0;

      for (let user of this.userlist) {

        if (user._id == mutedId) {

          this.userlist.splice(index, 1);
          this.userlistIDs.splice(index, 1);
          // this.thumbnails.splice(index, 1);

          break;

        }

        index++;

      }

      // TODO: Add user to user list when the user gets unmuted??

    })

  }
}
