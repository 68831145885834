<ios-status-bar></ios-status-bar>

<!-- <router-outlet></router-outlet> -->

@if (appService.showSplashScreen()) {

<rs-splash-screen></rs-splash-screen>

} @else {

@if (this.showNavigation()) {
<rs-nav-bar id="nav-bar-container"></rs-nav-bar>
}
<router-outlet></router-outlet>

}
