import { AppLanguageSelectGuard } from './app-language-select/app-language-select.guard';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TopBarComponent } from './nav-bar/nav-bar.component';
import { AppService } from "./app.service";
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { WebsocketModule } from './websocket/websocket.module';
import { AuthService } from './auth/auth.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SEOService } from './seo/seo.service';
import { Router } from '@angular/router';
import { AnalyticsModule } from './analytics/analytics.module';
import { ChatService } from './chat/chat.service';
import { MyProfileDataService } from './profiles/services/my-profile-data.service';
import { AuthInterceptor } from './auth/auth-interceptor';
import { AuthGuard } from './auth/auth.guard';
import { IconRegistryModule } from './icons/icon-registry.module';
import { CommonModule } from '@angular/common';
import { InitProfileGuard } from './profiles/init-profile/init-profile.guard';
import { UserListService } from './user-list/user-list.service';
import { IosStatusBarComponent } from './ios-status-bar/ios-status-bar.component';
import { SplashScreenComponent } from './splash-screen/splash-screen.component';
import { BlockUserService } from './utils/services/block-user.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    WebsocketModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AppRoutingModule,
    AnalyticsModule,
    TopBarComponent,
    IosStatusBarComponent,
    IconRegistryModule,
    SplashScreenComponent
  ],
  exports: [
    WebsocketModule,
    AppRoutingModule,
    HttpClientModule,
    IconRegistryModule,
    CommonModule
  ],
  providers: [
    // AppLanguageSelectGuard,
    AppService,
    AuthService,
    MyProfileDataService,
    UserListService,
    SEOService,
    ChatService,
    BlockUserService,
    AuthGuard,
    InitProfileGuard,
    AuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: AppRoutingModule.addLocalizedRoutes,
      multi: true,
      deps: [Router, LOCALE_ID, PLATFORM_ID],
    },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }


