import { Location, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, LOCALE_ID, OnDestroy, PLATFORM_ID, signal } from '@angular/core';
import { Subject, Subscription, filter } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { ChatService } from './chat/chat.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Paths } from './app-routing.module';

@Injectable({
  providedIn: 'root'
})
export class AppService implements OnDestroy {

  onBrowserPlattForm: boolean;
  backendUrl = environment.apiUrl;
  subscriptions: Subscription = new Subscription();
  currentURLPath = signal<string>("");
  showSplashScreen = signal<boolean>(false);

  // User Data

  ownId: string = null;
  ownThumbnail: string = null;
  public myID: string; // TODO: is ownID and myID the same??

  // Fields for structural behaviorr

  private _showHamburgerMenu = false;
  $showHamburgerMenuSubject = new Subject<boolean>();
  public get showHamburgerMenu() {
    return this._showHamburgerMenu;
  }
  public set showHamburgerMenu(show: boolean) {
    this._showHamburgerMenu = show;
    this.$showHamburgerMenuSubject.next(this._showHamburgerMenu);
  }
  locales = [
    { "de": "Deutsch" },
    { "en": "English" },
    { "es": "Español" },
    { "fr": "Français" },
    { "ms": "Melayu" },
    { "pt": "Português" },
    { "ru": "Русский" },
    { "hi": "हिन्दी" },
    { "zh": "简体中文" },
    { "it": "Italiano" },
    { "nl": "Nederlands" },
    { "pl": "Polski" },
    { "tr": "Türkçe" }
  ];

  constructor(
    private authService: AuthService,
    private chatService: ChatService,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(LOCALE_ID) public locale: string
  ) {

    this.onBrowserPlattForm = isPlatformBrowser(this.platformId);
    if (this.onBrowserPlattForm) this.setAuthStateListener();
    if (this.onBrowserPlattForm) this.startURLPathDetection();

  }
  ngOnDestroy(): void {

    if (this.subscriptions) this.subscriptions.unsubscribe();

  }
  private startURLPathDetection() {

    this.subscriptions.add(this.router.events.subscribe({
      next: (event) => {

        if (event instanceof NavigationEnd) {

          this.currentURLPath.set(this.route.firstChild.snapshot.routeConfig.path);

        }

      }
    }));

  }
  setAuthStateListener() {

    this.subscriptions.add(this.authService.getAuthStatusListener().subscribe({
      next: isAuthenticated => {

        this.showHamburgerMenu = false;
        if (isAuthenticated) this.chatService.getAllUnreadMessages();

      }
    }));

  }
}
