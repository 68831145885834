// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  apiUrl: "https://rattlestork.org/",
  websocketUrl: "https://rattlestork.org/",
  cookieDomain: "rattlestork.org",
  googlePlacesApiKey: "AIzaSyBy-CQwr8Ew2Uq8CocKXfYe71sqpTqnIjU",

};
